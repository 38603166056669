import React from "react";
import theme from "theme";
import { Theme, Link, Strong, Text, Image, Span, List, Box, Button, Structure, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Undetected CS2 Hacks: Counter-Strike 2 Cheats, Aimbot & ESP | Vajoner Menu
			</title>
			<meta name={"description"} content={"The best CS2 menu with no waiting on cs updates! For only $39.99 you get lifetime hacks for Counter Strike 2. No if ands or buts. Vajoner Menu is the last menu you'll need."} />
			<meta property={"og:title"} content={"Undetected CS2 Hacks: Counter-Strike 2 Cheats, Aimbot & ESP | Vajoner Menu"} />
			<meta property={"og:description"} content={"The best CS2 menu with no waiting on cs updates! For only $25 you get lifetime cheats for CS2. No if ands or buts. Vajoner Menu is the last menu you'll need."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/esppic1.png?v=2023-12-11T07:36:38.347Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/icon.png?v=2023-12-11T18:07:50.349Z"} type={"image/x-icon"} />
		</Helmet>
		<Text
			margin="0px 0px 0px 0px"
			color="#ffffff"
			text-align="center"
			padding="15px 0px 15px 0px"
			font="14px --fontFamily-sansTrebuchet"
			background="#0b0b0b"
		>
			<Strong>
				VAC & VACLIVE
			</Strong>
			{" "}UNDETECTED AS OF 5/6/2024 12:06 PM EST -{" "}
			<Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
				border-color="#e48716"
				color="#ff962b"
			>
				40/350 SLOTS AVAILABLE
			</Strong>
		</Text>
		<Box
			min-width="100px"
			min-height="100px"
			display="block"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/cs-2-guys.png?v=2023-12-11T06:05:26.945Z) 48% 0%/contain no-repeat"
			padding="205px 0px 60px 0px"
			md-padding="205px 0px 30px 0px"
		>
			<Image
				src="https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06:08:09.355Z"
				display="block"
				text-align="center"
				position="relative"
				justify-content="center"
				align-items="center"
				align-content="center"
				margin="-120px auto 0 auto"
				padding="0px 0px 20px 0px"
				sm-width="280px"
				md-width="260px"
				lg-width="260px"
				width="260px"
				srcSet="https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06%3A08%3A09.355Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06%3A08%3A09.355Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06%3A08%3A09.355Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06%3A08%3A09.355Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06%3A08%3A09.355Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06%3A08%3A09.355Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Untitled-6.png?v=2023-12-11T06%3A08%3A09.355Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw"
			/>
			<List
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				id="vajonerMenu"
				pointer-events="auto"
				text-align="center"
				color="#ffffff"
				list-style-type="none"
				position="static"
				display="flex"
				align-items="center"
				justify-content="center"
				font="16px --fontFamily-googleNunitoSans"
				md-width="100%"
				sm-display="block"
				sm-width="75%"
				sm-margin="0px auto 0px auto"
				md-display="flex"
			>
				<Text
					margin="0px 0px 0px 0px"
					display="inline-block"
					width="auto"
					id="buyNowBtn"
					font="16px --fontFamily-googleNunitoSans"
					md-display="inline-block"
					sm-display="block"
					sm-margin="0px 0px 0px 5px"
				>
					<Link
						href="https://buy.stripe.com/3cs4gN28H84qd7GbIJ"
						background="#000000"
						md-display="grid"
						sm-display="block"
						target="_blank"
					>
						Buy Now{" "}
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							$39.99
						</Span>
					</Link>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					display="inline-block"
					width="auto"
					font="16px --fontFamily-googleNunitoSans"
					md-display="inline-block"
					sm-display="block"
				>
					<Link href="#howitworks" md-display="block" sm-width="auto">
						How it works
					</Link>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					display="inline-block"
					width="auto"
					font="16px --fontFamily-googleNunitoSans"
					sm-display="block"
				>
					<Link href="#features" sm-display="block">
						Features
					</Link>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					display="inline-block"
					width="auto"
					font="16px --fontFamily-googleNunitoSans"
					sm-display="block"
				>
					<Link href="#faqs" sm-display="block">
						FAQs
					</Link>
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					display="inline-block"
					width="auto"
					font="16px --fontFamily-googleNunitoSans"
					sm-display="block"
					sm-padding="0px 5px 0px 0px"
				>
					<Link href="#reviews" sm-display="block">
						Reviews
					</Link>
				</Text>
			</List>
		</Box>
		<Text margin="0px 0px 15px 0px" text-align="center" color="#ffffff" font="normal normal 23px --fontFamily-sansHelvetica">
			Vajoner Menu is 100% external and 100% undetected by Vac and Vac Live.{"  "}
		</Text>
		<Box
			min-width="100px"
			min-height="100px"
			text-align="center"
			sm-width="95%"
			sm-margin="0px auto 0px auto"
			md-width="95%"
			lg-width="100%"
			md-margin="0px auto 0px auto"
			display="block"
			width="100%"
			height="500px"
			position="relative"
			sm-height="300px"
		>
			<Components.QuarklycommunityKitYouTube
				url="https://www.youtube.com/watch?v=cHBD1Y5ThvY"
				autoplay
				loop
				modestbranding
				width="891px"
				controls
				position="relative"
				height="500px"
				padding="500px 0px 0px 0px"
				margin="0px auto 0px auto"
				overflow-x="hidden"
				md-width="100%"
				sm-height="300px"
			>
				<Override slot="YouTube Button" background="--color-darkL2" />
				<Override
					slot="YouTube Content"
					bottom={0}
					left="-444px"
					right={0}
					top={0}
					position="absolute"
					height="500px"
					display="block"
					md-left="-50%"
					md-right={0}
					md-position="absolute"
					sm-height="300px"
				/>
				<Override slot="YouTube Button Overlay" height="500px" left={0} sm-height="300px" />
			</Components.QuarklycommunityKitYouTube>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			position="static"
			text-align="center"
			top="20px"
			bottom="50px"
			padding="30px 0px 0px 0px"
		>
			<Image
				src="https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07:12:11.477Z"
				display="inline-block"
				position="sticky"
				text-align="center"
				top="20px"
				bottom="20px"
				width="120px"
				opacity="0.5"
				srcSet="https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07%3A12%3A11.477Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07%3A12%3A11.477Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07%3A12%3A11.477Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07%3A12%3A11.477Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07%3A12%3A11.477Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07%3A12%3A11.477Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/spay.png?v=2023-12-11T07%3A12%3A11.477Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw"
			/>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			text-align="center"
			padding="20px 0px 50px 0px"
			md-width="100%"
			sm-padding="20px 5px 20px 5px"
		>
			<Text
				margin="0px 0px 0px 0px"
				color="#e48716"
				text-align="center"
				font="19px --fontFamily-googleNunitoSans"
				display="inline-block"
				width="780px"
				position="static"
				flex-direction="row-reverse"
				align-items="flex-start"
				justify-content="space-around"
				overflow-x="visible"
				align-content="flex-start"
				md-width="100%"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					"{"     "}
				</Strong>
				Vajoner Menu is a completely undetected cheat for Counter Strike 2. Our cheat includes Wall Hacks, Aim Bot, Trigger Bot, Enemy Hud Radar, C4 timer and Spectator list. This cheat has been tested for over a year and improved by our professional team of developers to ensure non-detection.{"     "}
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					"
				</Strong>
			</Text>
			<Text
				margin="18px 0px 0px 0px"
				color="#ffffff"
				text-align="center"
				font="19px --fontFamily-googleNunitoSans"
				display="block"
				width="100%"
				position="static"
				flex-direction="row-reverse"
				align-items="flex-start"
				justify-content="space-around"
				overflow-x="visible"
				align-content="flex-start"
				md-width="100%"
			>
				<Strong>
					VACLive Safe & Tested{" "}
					<Link href="https://emojipedia.org/check-mark-button">
						✅
					</Link>
					{"\n\n"}
				</Strong>
			</Text>
		</Box>
		<Box min-width="100px" min-height="100px" text-align="center" padding="20px 0px 50px 0px">
			<Text
				margin="0px 0px 0px 0px"
				color="#7b7b7b"
				text-align="center"
				font="15px --fontFamily-googleNunitoSans"
				display="inline-block"
				width="780px"
				position="static"
				flex-direction="row-reverse"
				align-items="flex-start"
				justify-content="space-around"
				overflow-x="visible"
				align-content="flex-start"
				md-width="100%"
			>
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					We are not responsible for your account.{" "}
					<Span text-decoration-line="underline">
						Cheat at your own risk.
					</Span>
				</Span>
			</Text>
		</Box>
		<Box min-width="100px" min-height="100px">
			<Text
				margin="0px 0px 0px 0px"
				text-align="center"
				color="#ffffff"
				font="900 35px &quot;Nunito Sans&quot;, sans-serif"
				as="h1"
			>
				100% Undetected Counter Strike 2 Cheats
			</Text>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			display="block"
			text-align="center"
			width="1001px"
			position="static"
			overflow-x="visible"
			overflow-y="visible"
			quarkly-title="How it works"
			id="howitworks"
			padding="50px 0px 100px 0px"
			margin="0px auto 0px auto"
			md-width="100%"
			md-padding="0 0px 50px 0px"
			sm-padding="0px 17px 0 17px"
		>
			<Text
				margin="0px 0px 85px 0px"
				color="--secondary"
				font="40px --fontFamily-googleNunitoSans"
				text-align="center"
				display="block"
				padding="0px 0px 0px 0"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				as="h2"
			>
				<Strong>
					How it works
				</Strong>
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="inline-block"
				position="static"
				width="410px"
				border-radius="4px"
				sm-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04:52:33.406Z"
					display="block"
					border-radius="8px"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04%3A52%3A33.406Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04%3A52%3A33.406Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04%3A52%3A33.406Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04%3A52%3A33.406Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04%3A52%3A33.406Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04%3A52%3A33.406Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_s1z4uvvq3t.png?v=2023-12-20T04%3A52%3A33.406Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				align-items="flex-start"
				justify-content="flex-start"
				flex-wrap="wrap-reverse"
				align-content="flex-start"
				overflow-y="visible"
				position="relative"
				display="inline-block"
				width="566px"
				margin="0px 0px 0px 20px"
				className="infoStep"
				sm-width="100%"
				sm-margin="0px 0px 0px 0"
			>
				<Text
					margin="0px 0px 30px 0px"
					color="#ffffff"
					text-align="left"
					display="block"
					justify-items="start"
					align-items="flex-start"
					justify-content="flex-start"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-content="flex-start"
					position="relative"
					font="16px --fontFamily-googleNunitoSans"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="700 17px &quot;Nunito Sans&quot;, sans-serif"
					>
						<Span
							text-transform="uppercase"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Span
								font="700 20px &quot;Nunito Sans&quot;, sans-serif"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Step 1.
							</Span>
						</Span>
					</Strong>
					<br />
					<br />
					<Span
						font="18px &quot;Nunito Sans&quot;, sans-serif"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Once you have purchased Vajoner Menu and joined our Discord, simply extract the download contents to a folder on your desktop.
						<br />
					</Span>
					<br />
					Once you’ve completed this, start CS2 and once loaded, load Vajoner Menu.
				</Text>
				<Text
					margin="0px 0px 0 0px"
					color="#e48716"
					text-align="left"
					display="block"
					justify-items="start"
					align-items="flex-start"
					justify-content="flex-start"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-content="flex-start"
					position="relative"
					font="16px/160% --fontFamily-googleNunitoSans"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					* You will hear 2 beeps when the cheat has injected.
					<br />
					* If you did not hear the activation beeps, contact support.
					<br />
					* You will see the radar immediately on the left side of your screen.
				</Text>
			</Box>
			<List
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 20px"
				list-style-type="none"
				as="ul"
				color="#ffffff"
				id="hotKeys"
				font="16px --fontFamily-googleNunitoSans"
				quarkly-title="HOT KEYS"
				md-text-align="left"
				md-display="inline-block"
				sm-padding="0px 0px 0px 0px"
				sm-width="100%"
				sm-position="relative"
				sm-left="-15px"
			>
				<Text
					margin="0px 0px 0px 0px"
					sm-display="block"
					sm-width="300px"
					sm-text-align="left"
					sm-position="relative"
					sm-left="4px"
				>
					Default Hot Keys
				</Text>
				<Text margin="0px 0px 0px 0px">
					[INSERT] → MENU
				</Text>
				<Text margin="0px 0px 0px 0px">
					[F5] → ESP TOGGLE
				</Text>
				<Text margin="0px 0px 0px 0px">
					[F9] → HEAD ESP
				</Text>
				<Text margin="0px 0px 0px 0px">
					[LALT] → TRIGGER BOT
				</Text>
			</List>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			display="block"
			text-align="center"
			width="1001px"
			position="static"
			overflow-x="visible"
			overflow-y="visible"
			quarkly-title="Step 2."
			margin="100px auto 100px auto"
			md-margin="50px auto 50px auto"
			md-width="100%"
			sm-padding="0px 18px 0px 18px"
			md-padding="0px 18px 0px 18px"
		>
			<Box
				min-width="100px"
				min-height="100px"
				align-items="flex-start"
				justify-content="flex-start"
				flex-wrap="wrap-reverse"
				align-content="flex-start"
				overflow-y="visible"
				position="relative"
				display="inline-block"
				width="566px"
				margin="0px 20px 0px 0px"
				className="infoStep"
				sm-width="100%"
			>
				<Text
					margin="0px 0px 30px 0px"
					color="#ffffff"
					text-align="left"
					display="block"
					justify-items="start"
					align-items="flex-start"
					justify-content="flex-start"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-content="flex-start"
					position="relative"
					font="16px --fontFamily-googleNunitoSans"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="700 17px &quot;Nunito Sans&quot;, sans-serif"
					>
						<Span
							text-transform="uppercase"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Span
								font="700 20px &quot;Nunito Sans&quot;, sans-serif"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Step 2.
							</Span>
						</Span>
					</Strong>
					<br />
					<br />
					<Span
						font="18px &quot;Nunito Sans&quot;, sans-serif"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Hit [Insert] to open Vajoner Menu.
						<br />
					</Span>
					<br />
					Here you can change various settings within ESP, Aimbot, Enemy Radar ant the Trigger Bot.
				</Text>
				<Text
					margin="0px 0px 0 0px"
					color="#e48716"
					text-align="left"
					display="block"
					justify-items="start"
					align-items="flex-start"
					justify-content="flex-start"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-content="flex-start"
					position="relative"
					font="16px --fontFamily-googleNunitoSans"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					* We recommend setting your desired hot keys to toggle trigger bot and aimbot cheats.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="inline-block"
				position="static"
				width="auto"
				md-margin="50px 0px 0px 0px"
				md-text-align="center"
				md-display="inline-block"
				sm-margin="20px 0px 0px 0px"
				sm-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08:14:32.665Z"
					display="block"
					border-radius="8px"
					width="auto"
					sm-display="inline-block"
					sm-text-align="center"
					sm-width="100%"
					srcSet="https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08%3A14%3A32.665Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08%3A14%3A32.665Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08%3A14%3A32.665Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08%3A14%3A32.665Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08%3A14%3A32.665Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08%3A14%3A32.665Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/Photoshop_OcX8iE1gDJ.png?v=2023-12-11T08%3A14%3A32.665Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw"
				/>
			</Box>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			display="block"
			text-align="center"
			width="1001px"
			position="static"
			overflow-x="visible"
			overflow-y="visible"
			quarkly-title="Step 3."
			margin="100px auto 100px auto"
			md-width="100%"
			md-margin="50px auto 50px auto"
			sm-width="100%"
			sm-margin="0 auto 50px auto"
			sm-padding="0px 17px 0px 17px"
			sm-text-align="center"
		>
			<Box
				min-width="100px"
				min-height="100px"
				display="inline-block"
				position="static"
				width="auto"
				sm-display="inline-block"
				sm-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02:05:50.087Z"
					display="block"
					border-radius="8px"
					width="auto"
					sm-width="100%"
					srcSet="https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02%3A05%3A50.087Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02%3A05%3A50.087Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02%3A05%3A50.087Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02%3A05%3A50.087Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02%3A05%3A50.087Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02%3A05%3A50.087Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6576a43c8d4a0c002027e288/images/ShareX_9q4j5QPfku.png?v=2023-12-20T02%3A05%3A50.087Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw"
				/>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				align-items="flex-start"
				align-content="flex-start"
				flex-wrap="wrap-reverse"
				justify-content="flex-start"
				overflow-y="visible"
				position="relative"
				display="inline-block"
				width="566px"
				margin="0px 0px 0px 20px"
				className="infoStep"
				md-margin="20px 0px 0px 20px"
				sm-margin="20px 0px 0px 0"
				sm-width="100%"
				sm-padding="0px 0 0px 0"
				sm-text-align="left"
			>
				<Text
					margin="0px 0px 30px 0px"
					color="#ffffff"
					text-align="left"
					display="block"
					justify-items="start"
					align-items="flex-start"
					justify-content="flex-start"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-content="flex-start"
					position="relative"
					font="16px --fontFamily-googleNunitoSans"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="700 17px &quot;Nunito Sans&quot;, sans-serif"
					>
						<Span
							text-transform="uppercase"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Span
								font="700 20px &quot;Nunito Sans&quot;, sans-serif"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Step 3.
							</Span>
						</Span>
					</Strong>
					<br />
					<br />
					<Span
						font="18px &quot;Nunito Sans&quot;, sans-serif"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Set Toggle Keybinds
						<br />
					</Span>
					<br />
					Access AimBot and Trigger Bot to set your desired toggle keys.
				</Text>
				<Text
					margin="0px 0px 0 0px"
					color="#e48716"
					text-align="left"
					display="block"
					justify-items="start"
					align-items="flex-start"
					justify-content="flex-start"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-content="flex-start"
					position="relative"
					font="16px/160% --fontFamily-googleNunitoSans"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					* By Default, all our pre-defined binds are toggle based only.{" "}
					<br />
					* We recommend LSHIFT for best results.
				</Text>
			</Box>
			<List
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 20px"
				list-style-type="none"
				as="ul"
				color="#ffffff"
				id="hotKeys"
				font="16px --fontFamily-googleNunitoSans"
				quarkly-title="HOT KEYS"
				md-text-align="center"
				md-padding="0px 0px 0px 0"
				md-width="fit-content"
				md-display="inline-block"
				sm-width="auto"
				sm-display="block"
				sm-text-align="left"
				sm-position="relative"
				sm-left="-17px"
			>
				<Text
					margin="0px 0px 0px 0px"
					sm-font="16px &quot;Nunito Sans&quot;, sans-serif"
					sm-padding="0px 0px 0px 6px"
					sm-text-align="left"
					sm-width="300px"
					sm-display="block"
				>
					Default Toggle Keys
				</Text>
				<Text margin="0px 0px 0px 0px">
					[LSHIFT] TRIGGER BOT
				</Text>
				<Text margin="0px 0px 0px 0px">
					[RCLICK] AIM BOT
				</Text>
			</List>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			quarkly-title="Step 4."
			text-align="center"
			max-width="90%"
			margin="0px auto 0px auto"
			width="998px"
			border-radius="5px"
			transition="opacity 5s ease 1s"
			background="#000000"
			md-width="90%"
		>
			<Button
				background="#f0a700"
				padding="50px 100px 50px 100px"
				width="100%"
				font="normal 300 30px/1.5 --fontFamily-googleNunitoSans"
				href="https://buy.stripe.com/3cs4gN28H84qd7GbIJ"
				md-padding="30px 30px 30px 30px"
				md-font="normal 300 22px/1.5 --fontFamily-googleNunitoSans"
				sm-padding="20px 20px 20px 20px"
				sm-font="normal 300 20px/1.5 --fontFamily-googleNunitoSans"
				type="link"
				target="_blank"
			>
				Don't wait any longer. Get Vajoner Menu Lifetime Today
			</Button>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			quarkly-title="FEATURES LIST"
			id="features"
			margin="100px auto 100px auto"
			width="max-content"
			md-width="100%"
			md-margin="50px auto 50px auto"
			sm-margin="50px auto 50px auto"
		>
			<Text
				margin="30px 0px 25px 0px"
				color="#ffffff"
				font="40px --fontFamily-googleNunitoSans"
				text-align="center"
				display="block"
				as="h2"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
					color="#ffc910"
				>
					Full Features List
				</Strong>
			</Text>
			<Structure>
				<Override slot="Content" sm-display="inline-block" sm-text-align="left">
					<Override slot="cell-0">
						<Text margin="0px 0px 20px 0px" color="#ffffff">
							<Strong>
								Box ESP
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Weapons
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Health
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Player Name
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Change Player Box Colors
						</Text>
						<Text margin="20pxpx 0px 0px 0px" color="#ffffff" quarkly-title="HEADING">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Bone ESP
								<br />
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Disable or Enable
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Change Enemy Team Color Bone ESP
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Change Team Color Bone ESP
						</Text>
						<Text margin="20pxpx 0px 0px 0px" color="#ffffff" quarkly-title="HEADING">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								General ESP
								<br />
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Toggle Team ESP
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Toggle Bomb Counter / Location ESP
						</Text>
					</Override>
					<Override slot="cell-1">
						<Text margin="0px 0px 20px 0px" color="#ffffff">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Aimbot
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Aimbot Toggle
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Aimbot Body Shot Selection
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Aimbot Sensitivity
						</Text>
						<Text margin="20pxpx 0px 0px 0px" color="#ffffff" quarkly-title="HEADING">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Stream Proof
								<br />
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Bypass Discord
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Bypass OBS
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Bypass Steam Broadcast
						</Text>
						<Text margin="20pxpx 0px 0px 0px" color="#ffffff" quarkly-title="HEADING">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Radar
								<br />
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Toggle Radar ESP
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Show Team
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Change Radar Display Type
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Change Radar Cross Line Color
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Change Radar Size, Proportion and Range
						</Text>
					</Override>
					<Override slot="cell-2">
						<Text margin="0px 0px 20px 0px" color="#ffffff">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Triggerbot
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Toggle Trigger Bot
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Set a pre-defined hot key to toggle
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Freely adjust click delay min and max
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Freely adjust click release min and max
						</Text>
						<Text margin="20px 0px 20px 0px" color="#ffffff">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Spectator List
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Full player spectator HUD is provided
						</Text>
						<Text margin="20px 0px 20px 0px" color="#ffffff">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Lifetime Automatic Updates
							</Strong>
						</Text>
						<Text margin="0px 0px 0px 0px" color="#fdfdfd" font="16px --fontFamily-googleNunitoSans">
							<Span
								color="#e48716"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								→
							</Span>
							{" "}Disable automatic updates (enabled by default)
						</Text>
					</Override>
					<Override slot="Cell 0th" sm-width="100%" sm-display="block" sm-margin="15px 0px 15px 0px" />
				</Override>
			</Structure>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			quarkly-title="FAQ"
			id="faqs"
			margin="100px 0px 0px 0px"
			color="#db9e1a"
			sm-margin="50px 0px 0px 0px"
			sm-font="16px &quot;Nunito Sans&quot;, sans-serif"
		>
			<Text
				margin="30px 0px 25px 0px"
				color="#ffc107"
				font="40px --fontFamily-googleNunitoSans"
				text-align="center"
				display="block"
				sm-font="40px --fontFamily-googleNunitoSans"
				as="h2"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Frequently Asked Questions{" "}
				</Strong>
			</Text>
		</Box>
		<Section
			padding="50px 0 120px 0"
			background="#000000"
			quarkly-title="FAQ-5"
			color="#ffffff"
			width="max-content"
			margin="0 auto 0 auto"
			md-width="100%"
		>
			<Text
				margin="0px 0px 70px 0px"
				font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
				color="#555a5f"
				sm-margin="0px 0px 50px 0px"
				text-align="center"
			>
				Here are the most frequently asked questions in our field and the{" "}
				<Span color="#cfcfcf" font="500 20px/30px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif">
					answers
				</Span>
				{" "}to them
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						How is this undetected?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Our cheat is masked under a common driver that steam always reads and accepts. After a year of testing on Casual, Competitive and Premier. We’ve determined the hack to be 100% undetected by VALVE and the games anti-cheat.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Have you had any cases of game bans?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						In essence, no. We have had an instance where a test account was game banned on CS:2 and only some hours later, it was removed. However this was in our early stages of testing.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						If I have issues with the cheat, can I get help?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						We do not offer screensharing or voice call support, however, you may submit a ticket on our discord and a creator will assist you.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Do you offer refunds?{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						As soon you receive the unique download files to your email, you officially own a licensed copy of the software, therefor a refund is not possible. In the event the cheat simply is not working on your first activation and attempting multiple steps to get it working does not work, we will provide a full refund.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						I was game banned, what do I do?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						Submit a ticket to our team, inform us truly of what happened. If you have any videos to provide as well we can make any determinations. We will guide you to purchase a new account and the necessary steps to remove CS:2 from your computer and a new key will be generated for Vajoner Menu that’s unique to your computer.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Can I invite my friends and earn a comission?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
						As of 2023, we have started a small program that allows customers to be affiliates. Simply bring in a customer and in the order notes, the referring customer will be required to list your email address. From there, we collect this. For every 2 customers you bring to Vajoner Menu, you can earn up to 1 Spectrum II cases with the key as a referral bonus.
					</Text>
				</Box>
			</Box>
		</Section>
		<Box
			min-width="100px"
			min-height="100px"
			quarkly-title="FAQ"
			id="reviews"
			color="#ebb809"
		>
			<Text
				margin="30px 0px 0 0px"
				color="#f6c119"
				font="40px --fontFamily-googleNunitoSans"
				text-align="center"
				display="block"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Reviews & Testimonials
				</Strong>
			</Text>
		</Box>
		<Section padding="0 0 80px 0" md-text-align="left">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				md-display="block"
				md-width="100%"
			/>
			<Box
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				md-justify-items="start"
				md-display="block"
				md-width="100%"
				md-padding="0px 17px 0px 17px"
			>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-secondary"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					md-display="block"
					md-margin="0px 0px 15px 0px"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="0px 0px 33px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw" />
					<Text
						margin="0px 0px 35px 0px"
						color="--secondary"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
						border-color="#ffffff"
					>
						“Undetected, easy to use. Lifetime updates... cant go wrong.”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--light" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								MeatmanZ
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Cheater
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-secondary"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					md-display="block"
					md-padding="50px 55px 50px 55px"
					md-margin="0px 0px 15px 0px"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20:09:22.144Z" margin="0px 0px 33px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20%3A09%3A22.144Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20%3A09%3A22.144Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20%3A09%3A22.144Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20%3A09%3A22.144Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20%3A09%3A22.144Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20%3A09%3A22.144Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/startfavorite.png?v=2021-08-30T20%3A09%3A22.144Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw" />
					<Text
						margin="0px 0px 35px 0px"
						color="--secondary"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
						border-color="#ffffff"
					>
						“This game is cheaters vs cheaters and this allows me to compete.”
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--light" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								Zup
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Cheater
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-secondary"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					md-display="block"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="0px 0px 33px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw" />
					<Text
						margin="0px 0px 35px 0px"
						color="--secondary"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"straight beamin"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--light" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								odoylerules
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Cheater
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-secondary"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					md-display="block"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="0px 0px 33px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw" />
					<Text
						margin="0px 0px 35px 0px"
						color="--secondary"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"works nice. The aimbot could use some touching up but the trigger bot is 100. Thnx"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--light" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								kek
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Cheater
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					padding="50px 55px 50px 55px"
					sm-padding="55px 40px 50px 55px"
					border-width="1px"
					border-style="solid"
					border-radius="24px"
					border-color="--color-secondary"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					md-display="block"
				>
					<Image src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20:05:47.446Z" margin="0px 0px 33px 0px" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/favourite-31.png?v=2021-08-30T20%3A05%3A47.446Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 1000px) 100vw,(max-width: 1500px) 100vw,100vw" />
					<Text
						margin="0px 0px 35px 0px"
						color="--secondary"
						font="--lead"
						lg-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"10/10"
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start" flex-direction="column">
						<Box>
							<Text color="--light" font="normal 600 16px/1.5 --fontFamily-googleSourceSansPro" margin="0px 0px 2px 0px">
								penis collector
							</Text>
							<Text color="--greyD1" font="--base" margin="0px 0px 0px 0px">
								Cheater
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Box min-width="100px" min-height="100px" margin="0px 0px 50px 0px" padding="0px 0px 20px 0px">
			<Box min-width="100px" min-height="100px" display="block">
				<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f" text-align="center">
					All game imagery is property of Valve.
					<br />
					We do not sell, trade or leak information of any customer or guest.
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						2024 Property of Vajoner Menu.
					</Strong>
				</Text>
			</Box>
		</Box>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6576a43c8d4a0c002027e286"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n#vajonerMenu li {\n  padding:5px;\n}\n\n#vajonerMenu li:first-of-type {\n  padding-left: 0;\n}\n\n#vajonerMenu li:last-of-type {\n  padding-right: 0;\n}\n\n#vajonerMenu li a  {\n  padding: 10px;\n  background-color: #e48716;\n  color: white;\n  text-decoration: none;\n  border: 1px solid #e48716;\n  border-radius: 3px;\n  font-weight: bold;\n}\n\nbody #buyNowBtn a {\n  background-color: transparent;\n  color: #e48716;\n  border-color: #e48716;\n}\n\n#vajonerMenu li a:hover,\n#vajonerMenu li a:focus {\n  background-color: transparent;\n  color: #e48716;\n  border-color: #e48716;\n}\n\n#buyNowBtn a > span {\n  color: white !important;\n}\n\n#hotKeys {\ntext-align:left;\npadding-left:0;\nmargin-top: 15px;\nmargin-left: 13px;\n}\n\n#hotKeys li {\ndisplay:inline-block;\ntext-align:center;\nborder: 1px solid #a78bee;\ncolor: #a78bee;\ntext-transform: uppercase;\nmargin: 0 6px;\npadding: 7px;\nfont-size: 15px;\nborder-radius: 4px;\n}\n\n#hotKeys li:first-of-type {\nborder: 0;\ncolor: white;\ntext-transform: none;\nmargin: 0;\npadding-left: 0;\n}\n\n.infoStep {\nvertical-align: top;\n}"}
			</style>
			<script
				src={"https://www.googletagmanager.com/gtag/js?id=G-JN9S5W2PJ3"}
				type={"text/javascript"}
				async={false}
				referrerpolicy={""}
				place={"endOfHead"}
				rawKey={"65827f589330b6521069b79e"}
			>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-JN9S5W2PJ3');"}
			</script>
			<script src={""} place={"endOfHead"} rawKey={"6582835633824cf334d9e36d"}>
				{"    (function(c,l,a,r,i,t,y){\n        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n        t=l.createElement(r);t.async=1;t.src=\"https://www.clarity.ms/tag/\"+i;\n        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n    })(window, document, \"clarity\", \"script\", \"k8qaauuui6\");"}
			</script>
		</RawHtml>
	</Theme>;
});